@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Monoton&family=Orbitron:wght@400..900&display=swap');a

body {
    display: flex;
}

h1 {
    color: rgb(171, 191, 209);
    font-family: Arial, Helvetica, sans-serif;
}

h2 {
    font-family: 'Times New Roman', Times, serif;
}

.form-text {
    @apply w-full md:w-2/3 xl:w-1/3 border border-slate-700 rounded-md m-2 p-2;
}

.form-label{
    @apply w-28 text-2xl
}

.font-comfortaa {
    font-family: "Comfortaa", sans-serif;
    font-optical-sizing: auto;
    font-weight: <weight>;
    font-style: normal;
  }